import { useTheme } from "@emotion/react";
import { TooltipPlacement } from "antd/es/tooltip";
import { Flex } from "components/Flex";
import InfoIcon from "components/icons/InfoIcon";
import PremiumIcon from "components/icons/PremiumIcon";
import ExploratoryTooltip from "components/tooltips/ExploratoryTooltip";
import { useUserData } from "hooks/useUser";
import { CombinedDatapoints } from "types/cube";
import CustomTooltip from "components/tooltips/Tooltip";
import { AllExploratoryOption } from "lib/options/exploratory";

interface Props {
  title: string;
  value?: CombinedDatapoints;
  isPremium?: boolean;
  isComingSoon?: boolean;
  isDisabled?: boolean;
  showTooltip?: boolean;
  placement?: TooltipPlacement;
  ytVideoCode?: string;
  isNew?: boolean;
  option: AllExploratoryOption;
  isShowPremiumIcon?: boolean;
}

const ExploratoryTitle = ({
  title,
  value,
  isPremium,
  isShowPremiumIcon,
  isDisabled,
  isComingSoon,
  showTooltip,
  ytVideoCode,
  isNew,
  option,
}: Props) => {
  const theme = useTheme();
  const user = useUserData();
  const disablePremium = isPremium && !user.isPremiumOrBasic;

  return (
    <Flex justify="space-between" align="center" css={{ width: "100%" }}>
      <CustomTooltip
        show={disablePremium}
        title={
          option.isComingSoon
            ? "Coming Soon"
            : !user.isPremiumOrBasic
              ? "To use this data point, upgrade to premium plan"
              : ""
        }
      >
        <span
          css={{
            flex: 5,
            color: !isPremium ? theme.colors.blue : theme.colors.black,
          }}
        >
          {title}
        </span>
      </CustomTooltip>

      <Flex justify="flex-end" align="center" gap={4} css={{ flex: 1 }}>
        {isNew && (
          <div
            css={{
              fontSize: theme.fontSizes.small,
              color: theme.colors.primary,
            }}
          >
            New
          </div>
        )}
        {disablePremium && (
          <Flex justify="flex-end" align="center">
              {isShowPremiumIcon && <PremiumIcon />}
          </Flex>
        )}
        {showTooltip && value && (
          <ExploratoryTooltip
            title={title}
            value={value}
            ytVideoCode={ytVideoCode}
          >
            <Flex
              justify="flex-end"
              align="center"
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
            >
              <InfoIcon />
            </Flex>
          </ExploratoryTooltip>
        )}
      </Flex>
    </Flex>
  );
};

export default ExploratoryTitle;
