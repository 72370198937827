import { Flex } from "components/Flex";
import { FC, MouseEventHandler, ReactNode } from "react";

interface Props {
  onClick: MouseEventHandler<HTMLDivElement>;
  icon: ReactNode;
  title: string;
  backgroundColor?: string;
  color?: string;
}

const RoundButton: FC<Props> = ({
  onClick,
  icon,
  title,
  backgroundColor,
  color,
}) => {
  return (
    <Flex
      justify="center"
      align="center"
      direction="column"
      css={(theme) => ({
        backgroundColor: backgroundColor || theme.colors.white,
        borderRadius: theme.radii.full,
        width: 49,
        height: 49,
        gap: theme.space[0],
      })}
      onClick={onClick}
    >
      {icon}
      <div
        css={(theme) => ({
          color: color || theme.colors.black,
          fontSize: theme.fontSizes.smaller,
        })}
      >
        {title}
      </div>
    </Flex>
  );
};

export default RoundButton;
