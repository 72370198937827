/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import mapLightBg from "../public/map-light.png";
import streetsFlatBg from "../public/streets-flat.png";
import streetsGlobeBg from "../public/streets-globe.png";
import { Center } from "./Center";
import { Flex } from "./Flex";

export const mapboxStyleMap = {
  globe: "mapbox://styles/gabriel416/clbt2ugok000514qtaam2tola",
  streets: "mapbox://styles/gabriel416/clbt5ifh7002w14rtxv6vc0up",
  light: "mapbox://styles/gabriel416/cl2ogbpnt005e15mz3bxfl5s2",
};

export const MapStyleSelector = ({ mapStyle, setMapStyle }) => {
  const [showMapStyle, setShowMapStyle] = useState(false);
  const mapStyleInfo = {
    light: {
      label: "Light",
      image: mapLightBg,
    },
    streets: {
      label: "Streets",
      image: streetsFlatBg,
    },
    globe: {
      label: "Globe",
      image: streetsGlobeBg,
    },
  };

  const getSharedBoxStyles = (option) => {
    return {
      width: "85px",
      height: "85px",
      backgroundImage: `url(${mapStyleInfo[option].image.src})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    };
  };

  const renderGeoBox = ({ onClick, label, option }) => {
    return (
      <div
        onClick={onClick}
        css={(theme) => ({
          textAlign: "center",
          position: "relative",
          color: theme.colors.white,
        })}
      >
        <div
          css={(theme) => ({
            ...getSharedBoxStyles(option),
            borderRadius: theme.radii.small,
            border: `2.5px solid ${
              showMapStyle ? theme.colors.black : theme.colors.white
            }`,
          })}
        ></div>
        {showMapStyle && (
          <Center>
            <p
              css={(theme) => ({
                cursor: "pointer",
                letterSpacing: theme.margin.small,
                color: theme.colors.black,
                textShadow: theme.textShadow,
              })}
            >
              {label}
            </p>
          </Center>
        )}
      </div>
    );
  };

  const renderMapOptions = () => {
    const mapOptions = ["globe", "light"];

    return mapOptions.map((option, i) => {
      if (option === mapStyle) {
        return null;
      }
      return (
        <div key={i}>
          {renderGeoBox({
            onClick: () => setMapStyle(option),
            label: mapStyleInfo[option].label,
            option,
          })}
        </div>
      );
    });
  };

  return (
    <>
      <Tippy
        interactive
        placement="right"
        content={
          <Flex
            align="center"
            justify="space-between"
            css={() => ({ zIndex: 99999 })}
          >
            {renderMapOptions()}
          </Flex>
        }
        visible={showMapStyle}
      >
        <div
          css={() => ({
            position: "absolute",
            top: "85%",
            left: "1.5%",
            cursor: "pointer",
          })}
        >
          {renderGeoBox({
            onClick: showMapStyle
              ? () => setShowMapStyle(false)
              : () => setShowMapStyle(true),
            label: "Close",
            option: mapStyle,
          })}
        </div>
      </Tippy>
    </>
  );
};
