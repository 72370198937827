// Dependencies
import { UilTimes } from "@iconscout/react-unicons";
import Tippy from "@tippyjs/react";
import { event } from "nextjs-google-analytics";

// Utilities
import useFilters from "hooks/useFilters";
import { slugify } from "lib/helpers";
import { exploratoryMap } from "lib/options/exploratoryMap";
import { Dispatch, SetStateAction } from "react";
import { Datapoints, CombinedDatapoints } from "types/cube";
import { IFilter } from "types/options";

interface Props {
  index: number;
  filter: IFilter;
  exploratory: CombinedDatapoints;
  lastFilterAsExploratory: boolean;
  setExploratory: Dispatch<SetStateAction<CombinedDatapoints>>;
  setExpoFilters: Dispatch<SetStateAction<IFilter[] | undefined>>;
  expoFilters: IFilter[];
}

function FilterLabel({
  exploratory,
  setExploratory,
  filter,
  lastFilterAsExploratory,
  index,
  setExpoFilters,
  expoFilters,
}: Props) {
  const { selectedFilters, setSelectedFilters } = useFilters();

  return (
    <p
      css={(theme) => ({
        textAlign: "left",
        display: "flex",
        alignItems: "center",
        fontSize: theme.fontSizes.default,
      })}
    >
      <Tippy
        placement="auto"
        content={"Click to set this filter as exploratory"}
      >
        <span
          className="selected-filter-label"
          css={(theme) => ({
            fontSize: theme.fontSizes.medium,
          })}
          onClick={() => {
            for (const key in exploratoryMap) {
              if (exploratoryMap[key].filterName === filter.label) {
                if (exploratory !== exploratoryMap[key].code) {
                  setExploratory(exploratoryMap[key].code);
                }
                break;
              }
            }
          }}
        >
          {filter.label}
        </span>
      </Tippy>
      {filter.isPreset && (
        <>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={`//${process.env.NEXT_PUBLIC_VERCEL_URL}/reventure-icon.jpeg`}
            alt="Reventure Icon"
            css={(theme) => ({
              borderRadius: theme.radii.full,
              marginLeft: theme.margin.small,
              width: "30px",
              height: "30px",
            })}
          />
        </>
      )}
      <UilTimes
        css={(theme) => ({
          borderRadius: theme.radii.full,
          color: theme.colors.gray200,
          marginLeft: theme.margin.small,
          cursor: "pointer",
          backgroundColor: theme.colors.radioBorder,
          width: theme.iconSize.small,
          height: theme.iconSize.small,
        })}
        onClick={() => {
          event(`filter-remove-${slugify(filter.label)}`, {
            category: "Filter",
            label: `Remove ${filter.label}`,
          });

          setSelectedFilters(
            selectedFilters.filter((f) => f.label !== filter.label),
          );
          setExpoFilters(expoFilters.filter((f) => f.label !== filter.label));
          if (lastFilterAsExploratory) {
            if (index === selectedFilters.length - 1 && index > 0) {
              const previousFilter = selectedFilters[index - 1];
              for (const key in exploratoryMap) {
                if (exploratoryMap[key].filterName === previousFilter.label) {
                  if (exploratory !== exploratoryMap[key].code) {
                    setExploratory(exploratoryMap[key].code);
                  }
                  break;
                }
              }
            }
          }
        }}
      />
    </p>
  );
}

export default FilterLabel;
