import { UilPlus } from "@iconscout/react-unicons";
import { Flex } from "../../Flex";
import { Dispatch, SetStateAction } from "react";

interface Props {
  onClick(): void;
}

function AddFilterButton({ onClick }: Props) {
  return (
    <Flex
      align="center"
      css={(theme) => ({
        gap: theme.margin.default,
        span: { fontSize: theme.fontSizes.medium },
        cursor: "pointer",
      })}
      onClick={onClick}
    >
      <Flex
        justify="center"
        align="center"
        css={(theme) => ({
          backgroundColor: theme.colors.primary,
          borderRadius: theme.radii.full,
          width: theme.iconSize.default,
          height: theme.iconSize.default,
        })}
      >
        <UilPlus
          css={(theme) => ({
            color: theme.colors.white,
            width: 18,
            height: 18,
          })}
        />
      </Flex>

      <span>Add data filters</span>
    </Flex>
  );
}

export default AddFilterButton;
