/** @jsxImportSource @emotion/react */

import DatapointsDrawer from "components/Drawer/drawers/DatapointsDrawer";
import useDrawer from "components/Drawer/useDrawer";
import FeedbackButton from "components/Feedback/FeedbackButton";
import Map from "components/Map";
import MapControls from "components/MapControls/MapControls";
import { MapLegend } from "components/MapLegend";
import ShareProgress from "components/ShareProgress";
import MobileMapHeader from "components/header/MobileMapHeader";
import { MapSidebarProvider } from "components/provider/MapSidebarProvider";
import { PaymentModalContext } from "components/provider/PaymentModalProvider";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import { TooltipTour } from "components/tour/Tour";
import useFilters from "hooks/useFilters";
import useGeoExploratory from "hooks/useGeoExploratory";
import useGeoSearch from "hooks/useGeoSearch";
import useProgressQuery from "hooks/useProgressQuery";
import { useUserData } from "hooks/useUser";
import { presetExploratoryOptions } from "lib/constants";
import { createGraphData } from "lib/helpers/graphHelpers";
import { exploratoryMap } from "lib/options/exploratoryMap";
import { isEmpty } from "lodash";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { Geo, Scale, SearchFrom } from "types/MapContext";
import { Datapoints } from "types/cube";
import { IFilter } from "types/options";
import {
  AuthModalContext,
  MapContext,
  ResponsiveContext,
} from "../lib/context";
import { useMounted } from "../lib/hooks";
import { trackNetworkType } from "lib/helpers/trackNetworkType";
import { useMap } from "react-map-gl";

const MapHeader = dynamic(() => import("components/header/MapHeader"), {
  ssr: false,
});
const MapSidebar = dynamic(() => import("components/sidebar/MapSidebar"), {
  ssr: false,
});

export default function Dashboard() {
  const { isTabletOrMobile } = useContext(ResponsiveContext);
  const { showPaymentModal } = useContext(PaymentModalContext);
  const { setShowAuthModal, setAuthType, showAuthModal, showTellUsMore } =
    useContext(AuthModalContext);
  const { showPlanModal } = useContext(PlanModalContext);
  const router = useRouter();
  const userData = useUserData();
  const { drawerType, setShow } = useDrawer();
  const { progressQuery, setProgressQuery } = useProgressQuery();
  const {
    geo,
    searchId,
    focusSearch,
    filteredMetros,
    reset,
    setGeo,
    setLat,
    setLong,
    setZoom,
    setSearchId,
    searchByQuery,
    setFromLastVisitedArea,
  } = useGeoSearch();

  const {
    setSelectedFilters,
    selectedFilters,
    exploratory,
    setExploratory,
    handleExploratoryChange,
    setGraphQuery,
  } = useFilters();

  useEffect(() => {
    trackNetworkType();
  }, []);

  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [scale, setScale] = useState<Scale[]>([]);
  const [lastFilterAsExploratory, setLastFilterAsExploratory] = useState(false);
  const [tableView, setTableView] = useState(false);
  const [geoCount, setGeoCount] = useState(0);
  const [showToolTip, setShowToolTip] = useState(true);
  const [mapStyle, setMapStyle] = useState("globe");
  const [showSidebar, setShowSidebar] = useState(true);
  const [showMap, setShowMap] = useState(false);
  const [isShareUrlRendered, setIsShareUrlRendered] = useState<boolean>(false);
  const [graphView, setShowGraphView] = useState<boolean>(false);
  const [graphData, setGraphData] = useState({});

  const params = router.query;

  const mounted = useMounted();

  const { exploratoryOptions } = useGeoExploratory(geo);

  useEffect(() => {
    const search = window.location?.search || "";
    if (search.includes("passwordReset") && !userData?.uid) {
      setAuthType("login");
      setShowAuthModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isTabletOrMobile) {
      setShowSidebar(true);
    }
    if (isTabletOrMobile) {
      setShowToolTip(false);
      setShowSidebar(false);
    }
  }, [isTabletOrMobile]);

  useEffect(() => {
    if (router.isReady) {
      if (isEmpty(params)) {
        setFromLastVisitedArea();
      }
      if (router.query?.graphView !== "true") {
        setGraphQuery(false);
      }
      if (Object.keys(params).length > 0) {
        searchByQuery(
          (params?.GEOID ? params?.GEOID : params?.query) as string,
          {
            forceGeo: params?.geo as Geo,
            forceLatLng: params?.lnglat
              ? JSON.parse(params?.lnglat as string)
              : null,
            forceZoom: parseFloat(params?.zoom as string),
            searchedFrom: params.searchRedirected
              ? SearchFrom.LANDING
              : SearchFrom.MAP,
          },
        );
      }
      if (params?.filters) {
        const filters: IFilter[] = JSON.parse(params?.filters as string);
        setSelectedFilters(filters);
      }
      if (params?.dataPoint) {
        setExploratory(params?.dataPoint as Datapoints);
      }
      if (params?.geo) {
        setGeo(params?.geo as Geo);
      }
      if (params?.lnglat && !params?.query) {
        const { lat, lng } = JSON.parse(params?.lnglat as string);
        setLat(lat);
        setLong(lng);
        setZoom(parseFloat(params?.zoom as string));
        setSearchId({
          long: lng,
          lat,
          zoom: parseFloat(params?.zoom as string),
        });
      }

      if (!isShareUrlRendered && router.query?.graphView === "true") {
        const graphData = createGraphData(
          (router.query?.geo || geo) as Geo,
          router.query?.GEOID as string,
        );
        if (graphData?.GEOID) {
          setGraphData(graphData);
          setShowGraphView(true);
          setIsShareUrlRendered(() => true);
        }
      }

      router.push({ pathname: "/dashboard", query: {} }, undefined, {
        shallow: true,
      });

      setShowMap(true);
    }
  }, [router.isReady]);

  useEffect(() => {
    if (geo === "zip") {
      setSelectedFilters(
        selectedFilters.map((data) => {
          return {
            label: data.label,
            val: data.val,
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredMetros]);

  useEffect(() => {
    if (!exploratoryOptions.some((option) => option.value === exploratory)) {
      let category = localStorage.getItem("category");
      const radio = localStorage.getItem("radio");

      if (radio === "rent_for_apartments" && category === "null") {
        category = "Investor Metrics";
        setExploratory(exploratoryMap.rent_for_apartments.code);
      } else {
        category = "null";
        setExploratory(exploratoryMap.home_value.code);
      }
      localStorage.setItem("category", category);
    }
    const updatedFilters = selectedFilters.map((filter) => ({
      ...filter,
    })) as IFilter[];

    setSelectedFilters(
      updatedFilters.filter((selectedFilter) => {
        return (
          (exploratoryOptions as any[]).find(
            (option) => option.label === selectedFilter.label,
          ) ||
          presetExploratoryOptions.find(
            (option) => option.label === selectedFilter.label,
          ) ||
          (selectedFilter.label === "state_code" &&
            ![Geo.ZIP, Geo.STATE].includes(geo))
        );
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geo, params?.filters]);

  useEffect(() => {
    let params = {
      geo,
      dataPoint: exploratory,
    };

    setProgressQuery((prevState) => ({ ...prevState, ...params }));
  }, [geo, exploratory]);

  useEffect(() => {
    if (showPaymentModal) {
      reset();
    }
  }, [showPaymentModal]);

  useEffect(() => {
    if (isTabletOrMobile) {
      setShowSidebar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId]);

  // Hack for iOS viewport scroll bug
  useEffect(() => {
    const container = document.getElementById("dashboard-container");
    if (!container) {
      return;
    }

    const handleResize = () => {
      container.style.height = `${window.innerHeight}px`;
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Run once on mount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isTabletOrMobile]);

  if (!mounted) {
    return <></>;
  }

  return (
    <MapContext.Provider
      value={{
        scale,
        geoCount,
        mapStyle,
        graphData,
        tableView,
        graphView,
        showToolTip,
        showShareModal,
        isTabletOrMobile,
        isShareUrlRendered,
        setScale,
        setGeoCount,
        setMapStyle,
        setTableView,
        setShowToolTip,
        setShowGraphView,
        setShowShareModal,
        setIsShareUrlRendered,
      }}
    >
      <MapSidebarProvider>
        <div
          id="dashboard-container"
          css={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            width: "100vw",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <main
            css={() => ({
              display: "flex",
              flexGrow: 1,
              minHeight: 0,
            })}
          >
            {!isTabletOrMobile && (
              <MapSidebar
                visible={showSidebar}
                geo={geo}
                handleExploratoryChange={handleExploratoryChange}
                exploratory={exploratory}
              />
            )}
            <div
              css={(theme) => ({
                width: "100%",
                height: "100%",
                position: "relative",
                display: isTabletOrMobile && showSidebar ? "none" : "flex",
                flexDirection: "column",
                background: theme.colors.panel,
              })}
            >
              {showMap && <Map setIsMapLoaded={setIsMapLoaded} />}

              {isTabletOrMobile ? (
                <MobileMapHeader
                  handleExploratoryChange={handleExploratoryChange}
                />
              ) : (
                <>
                  <MapHeader
                    sidebarVisible={showSidebar}
                    lastFilterAsExploratory={lastFilterAsExploratory}
                  />
                  <MapLegend
                    scale={scale}
                    exploratory={exploratory}
                    geo={geo}
                  />
                </>
              )}
              <FeedbackButton />
              <MapControls
                sidebarVisible={showSidebar}
                lastFilterAsExploratory={lastFilterAsExploratory}
              />
              <DatapointsDrawer show={drawerType} setShow={setShow} />
            </div>
          </main>

          <ShareProgress
            params={progressQuery}
            showModal={showShareModal}
            setShowModal={setShowShareModal}
          />
          {isMapLoaded &&
            showMap &&
            !showShareModal &&
            !showPaymentModal &&
            !showPlanModal &&
            !showAuthModal &&
            !graphView &&
            !tableView &&
            !router.query.showDatapointModal &&
            !showTellUsMore &&
            !router.query?.redirect_status && <TooltipTour />}
        </div>
      </MapSidebarProvider>
    </MapContext.Provider>
  );
}
