import { getFixedForExploratory } from "lib/helpers";
import CurrencyInput from "react-currency-input-field";
import { CurrencyInputProps } from "react-currency-input-field/dist/components/CurrencyInputProps";
import { FilterValues } from "types/options";

interface Props {
  exploratoryCode: string;
  isCurrency: boolean;
  isMinInput?: boolean;
  onValueChange: CurrencyInputProps["onValueChange"];
  state: FilterValues;
  suffix: "" | "%";
}

function FilterInput({
  exploratoryCode,
  isCurrency,
  isMinInput = false,
  onValueChange,
  state,
  suffix,
}: Props) {
  return (
    <CurrencyInput
      placeholder="Please enter a number"
      value={((isMinInput ? state[0] : state[1]) as number).toFixed(
        getFixedForExploratory(exploratoryCode),
      )}
      prefix={isCurrency ? "$" : ""}
      allowNegativeValue={isCurrency ? false : true}
      decimalsLimit={isCurrency ? 0 : 2}
      suffix={isCurrency ? "" : suffix}
      onValueChange={onValueChange}
      css={(theme) => ({
        fontFamily: "inherit",
        padding: "0px 2px",
        width: 85,
        height: 29,
        borderRadius: theme.radii.small,
        fontSize: theme.fontSizes.small,
        outline: "none",
        border: `1px solid ${theme.colors.inputBorder}`,
        background: theme.colors.white,
        textAlign: "center",
      })}
    />
  );
}

export default FilterInput;
