import Image from "next/image";
import ArrowUp from "../../public/arrow-up-black.svg";

const PenalChevron = ({ active }) => {
  return (
    <Image
      src={ArrowUp}
      alt="Toggle Panel"
      css={(theme) => ({
        transform: `rotate(${!active ? "180deg" : "0deg"})`,
        transition: "transform 0.3s ease",
      })}
    />
  );
};

export default PenalChevron;
