const ArrowLeft = ({ fill }: { fill?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M5,6.41L6.41,5L17,15.59V9H19V19H9V17H15.59L5,6.41Z"
        fill={fill || "#ffffff"}
      />
    </svg>
  );
};

export default ArrowLeft;
