import { useTheme } from "@emotion/react";
import { Flex } from "components/Flex";
import { MapFilters } from "components/MapFilter/MapFilters";
import FiltersSets from "components/header/FilterSets";
import CrossIcon from "components/icons/CrossIcon";
import PremiumIcon from "components/icons/PremiumIcon";
import { useUserData } from "hooks/useUser";
import { Geo } from "types/MapContext";

interface Props {
  geo: Geo;
  showFilters: boolean;
  setShowFilters: (val: boolean) => void;
  lastFilterAsExploratory: boolean;
}

function FiltersModal({
  geo,
  showFilters,
  setShowFilters,
  lastFilterAsExploratory,
}: Props) {
  const user = useUserData();
  const theme = useTheme();

  return (
    <>
      <div
        css={() => ({
          display: showFilters ? undefined : "none",
          position: "absolute",
          width: "100vw",
          height: "100%",
          zIndex: 1050,
          background: "rgba(0, 0, 0, 0.31)",
          backdropFilter: "blur(2.5px)",
        })}
        onClick={() => setShowFilters(false)}
      />
      <div
        css={(theme) => ({
          position: "absolute",
          backgroundColor: theme.colors.white,
          width: "100%",
          maxHeight: "100%",
          zIndex: 1050,
          bottom: showFilters ? 0 : "-100%",
          transition: "bottom 0.3s ease, height 0.3s ease",
          WebkitTransition: "bottom 0.3s ease, height 0.3s ease",
          MozTransition: "bottom 0.3s ease, height 0.3s ease",
          msTransition: "bottom 0.3s ease, height 0.3s ease",
          OTransition: "bottom 0.3s ease, height 0.3s ease",

          overflow: "scroll",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        })}
      >
        <Flex
          justify="space-between"
          align="center"
          css={(theme) => ({
            borderBottom: `1px solid ${theme.colors.inputBorder}`,
            padding: "0 27px",
            height: 51,

            span: {
              fontSize: 24,
              fontWeight: theme.fontWeights.bold,
              color: theme.colors.black,
            },
          })}
        >
          <span>
            Filters
            <span css={{ marginLeft: theme.margin.small }}>
              {!user.isPremiumOrBasic && <PremiumIcon />}
            </span>
          </span>

          <Flex
            justify="center"
            align="center"
            onClick={() => setShowFilters(false)}
          >
            <CrossIcon />
          </Flex>
        </Flex>

        <FiltersSets geo={geo} />

        <MapFilters lastFilterAsExploratory={lastFilterAsExploratory} />
      </div>
    </>
  );
}

export default FiltersModal;
