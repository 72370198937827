import { Tooltip } from "antd";
import { TooltipProps } from "antd/lib/tooltip";
import { ResponsiveContext } from "lib/context";
import { useContext } from "react";

type Props = {
  show?: boolean;
} & TooltipProps;

const CustomTooltip = (props: Props) => {
  const { isTabletOrMobile } = useContext(ResponsiveContext);

  if (!props.show) return props.children;

  return (
    <Tooltip
      placement={props.placement || isTabletOrMobile ? "bottom" : "right"}
      trigger={"hover"}
      overlayClassName="custom-tooltip"
      destroyTooltipOnHide
      {...props}
    >
      {props.children}
    </Tooltip>
  );
};

export default CustomTooltip;
