import { FC } from "react";

interface Props {
  width?: number | string;
  height?: number | string;
  color?: string;
  innerColor?: string;
}

const PremiumIcon: FC<Props> = ({ width, height, color, innerColor }) => {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || "14"}
          height={height || "14"}
          viewBox="0 0 14 14"
          fill="none"
      >
        <rect
            width={width || "14"}
            height={height || "14"}
            rx="7"
            fill={color || "#FFCF55"}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.18264 4.24314C5.83289 4.65191 5.36753 5.19754 5.14855 5.45571C4.76673 5.90591 4.74537 5.92286 4.62625 5.86988C4.55793 5.83948 4.14291 5.6338 3.704 5.41278C2.73961 4.92713 2.59703 4.86492 2.44841 4.86492C2.30807 4.86492 2.09961 5.06651 2.09961 5.20225C2.09961 5.25665 2.39095 6.27933 2.74698 7.4749C3.10302 8.67043 3.43793 9.79539 3.49126 9.97479C3.66376 10.5554 3.28427 10.4985 6.98082 10.4985C9.06232 10.4985 10.225 10.4811 10.2834 10.4492C10.3803 10.3963 10.467 10.1287 11.3303 7.22033C11.465 6.76624 11.6483 6.14913 11.7375 5.8489C11.8266 5.54872 11.8996 5.25815 11.8996 5.20323C11.8996 5.05792 11.6902 4.86492 11.5324 4.86492C11.4588 4.86492 10.919 5.10512 10.3291 5.40045L9.25932 5.93593L9.08454 5.72826C8.98839 5.61404 8.52774 5.07254 8.06086 4.52497C7.2341 3.55536 7.20684 3.52899 7.01531 3.51467L6.8186 3.5L6.18264 4.24314ZM7.79331 5.41128C8.75352 6.54513 9.00514 6.80752 9.13235 6.80752C9.18824 6.80752 9.5909 6.63249 10.0272 6.41857C10.4635 6.20464 10.8308 6.04019 10.8434 6.05306C10.8561 6.06598 10.6237 6.89664 10.3269 7.89897L9.78742 9.72143H7.00185H4.21627L3.99543 8.98081C3.87398 8.57349 3.6295 7.75406 3.45215 7.15996C3.2748 6.56581 3.14074 6.06841 3.15425 6.05467C3.16771 6.04088 3.53574 6.20464 3.97203 6.41857C4.40832 6.63249 4.80293 6.80752 4.84903 6.80752C4.9966 6.80752 5.12262 6.6798 6.04815 5.59218C6.54757 5.00523 6.97568 4.52497 6.99947 4.52497C7.02325 4.52497 7.38048 4.92378 7.79331 5.41128Z"
            fill={innerColor || "#1D2126"}
            stroke={innerColor || "#1D2126"}
            strokeWidth="0.6"
        />
      </svg>
  );
};

export default PremiumIcon;
