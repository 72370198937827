/** @jsxImportSource @emotion/react */

import useGeoExploratory from "hooks/useGeoExploratory";
import { getMapDisplayValue } from "../lib/helpers";
import { Flex } from "./Flex";
import { Text } from "./Text";

export const MapLegend = ({ scale, exploratory, geo }) => {
  const { exploratoryOptions } = useGeoExploratory(geo);

  const renderMapScale = () => {
    return (
      <Flex
        css={(theme) => ({
          width: "100%",
        })}
      >
        {scale.map((data, i) => {
          const [_, hex] = data;

          return (
            <div
              key={i}
              css={(theme) => ({
                flex: 1,
                height: 19,
                backgroundColor: hex,
              })}
            ></div>
          );
        })}
      </Flex>
    );
  };

  const renderLowerUpperVals = () => {
    const loopList = scale;
    return loopList.map((data, i) => {
      const [val] = data;
      const sanitizedVal = isNaN(val) ? 0 : parseFloat(val);
      const isFirstOrLast = i === 0 || loopList.length - 1 === i;

      return isFirstOrLast ? (
        <Text
          css={(theme) => ({
            marginTop: theme.margin.small,
            marginRight: i === 0 ? theme.margin.default : 0,
            marginLeft: loopList.length - 1 === i ? theme.margin.default : 0,
            textShadow: theme.textShadow,
          })}
          key={i}
        >{`${getMapDisplayValue({ exploratory, value: sanitizedVal })}`}</Text>
      ) : null;
    });
  };

  return (
    <Flex
      align="center"
      justify="center"
      direction="column"
      css={(theme) => ({
        position: "absolute",
        bottom: 6,
        right: 68,
        minWidth: "100px",
        gap: 11,
      })}
    >
      {scale && scale.length && exploratory ? (
        <>
          <div
            css={(theme) => ({
              textAlign: "center",
              padding: "6px 8px ",
              backgroundColor: theme.colors.primary,
              borderRadius: 46,
              fontSize: 12,
              fontWeight: 700,
              color: theme.colors.white,
            })}
          >
            {`Data Point: ${
              exploratoryOptions.find((opt) => opt.value === exploratory)?.label
            }`}
          </div>

          <div>
            <Flex justify="flex-end" align="center">
              {scale && scale.length ? renderMapScale() : null}
            </Flex>
            <Flex align="center" justify="space-between">
              {renderLowerUpperVals()}
            </Flex>
          </div>
        </>
      ) : null}
    </Flex>
  );
};
