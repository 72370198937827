import { exploratoryGroupMap } from "lib/options/exploratory";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from "react";

type ContextType = {
  activeTab: string | undefined;
  setActiveTab: Dispatch<SetStateAction<string | undefined>>;
};

export const MapSidebarContext = createContext<ContextType>({
  activeTab: undefined,
  setActiveTab: () => {},
});

export const MapSidebarProvider = ({ children }: { children: ReactNode }) => {
  const [activeTab, setActiveTab] = useState<string | undefined>(
    exploratoryGroupMap.popular,
  );
  return (
    <MapSidebarContext.Provider value={{ activeTab, setActiveTab }}>
      {children}
    </MapSidebarContext.Provider>
  );
};
