import { useTheme } from "@emotion/react";
import { doc, updateDoc } from "firebase/firestore";
import { useTour } from "hooks/useTour";
import { useUserData } from "hooks/useUser";
import { AuthModalContext, ResponsiveContext } from "lib/context";
import { db } from "lib/firebase";
import { useMounted } from "lib/hooks";
import { useRouter } from "next/router";
import { useContext, useEffect, useRef, useState } from "react";
import JoyRide from "react-joyride";
import { TooltipComponent } from "./TooltipComponent";
import { MapSidebarContext } from "components/provider/MapSidebarProvider";
import { exploratoryGroupMap } from "lib/options/exploratory";

export const TooltipTour = () => {
  const theme = useTheme();
  const mount = useMounted();
  const {
    currentStep,
    setCurrentStep,
    setSteps,
    steps,
    onboardingSteps,
    isTourPaused,
    setIsTourPaused,
  } = useTour();
  const [stepsVersion, setStepsVersion] = useState<"v1" | "v2" | "v3">("v1");
  const [timerState, setTimerState] = useState<{
    timer: NodeJS.Timeout | null;
    type: "skip" | "other";
  }>();
  const router = useRouter();
  const user = useUserData();
  const { showAuthModal } = useContext(AuthModalContext);
  const ref = useRef<any>(null);
  const { setActiveTab } = useContext(MapSidebarContext);
  const { isTabletOrMobile } = useContext(ResponsiveContext);

  useEffect(() => {
    if (
      !showAuthModal &&
      router.pathname.startsWith("/dashboard") &&
      !user.isLoading
    ) {
      const onboardingPromptInfo = JSON.parse(
        localStorage.getItem("onboardingInfo") || "{}",
      );
      if (
        !onboardingPromptInfo?.step &&
        (!user.uid || (user.uid && user.id && !user?.onboardingInfo))
      ) {
        setTimeout(
          () => {
            if (router.query.query) {
              setStepsVersion("v2");
              setSteps(onboardingSteps.v2);
              updateOnboardingInfo(1, "v2");
            } else {
              setStepsVersion("v1");
              setSteps(onboardingSteps.v1);
              updateOnboardingInfo(1, "v1");
            }
            setCurrentStep(0);
          },
          isTabletOrMobile ? 20000 : 0,
        );
      } else if (user.uid && user.id) {
        if (user.loginCount! === 2) {
          const step =
            user.onboardingInfo?.version === "v3"
              ? user.onboardingInfo.step
              : 0;
          setSteps(onboardingSteps.v3);
          setCurrentStep(step);
          setStepsVersion("v3");
          updateOnboardingInfo(1, "v3");
        } else if (user.onboardingInfo?.step) {
          setSteps(onboardingSteps[user.onboardingInfo.version]);
          setCurrentStep(user.onboardingInfo.step);
          setStepsVersion(user.onboardingInfo.version);
          updateOnboardingInfo(
            user.onboardingInfo.step + 1,
            user.onboardingInfo.version,
          );
        }
      } else if (onboardingPromptInfo.step) {
        setTimeout(
          () => {
            setSteps(onboardingSteps[onboardingPromptInfo.version]);
            setCurrentStep(onboardingPromptInfo.step);
            setStepsVersion(onboardingPromptInfo.step);
          },
          isTabletOrMobile ? 20000 : 0,
        );
      }
      if (onboardingPromptInfo.step && !user?.onboardingInfo?.step && user.id) {
        updateOnboardingInfo(
          onboardingPromptInfo.step,
          onboardingPromptInfo.version,
        );
      }
    }

    return () => {
      setSteps([]);
      setCurrentStep(0);
      setIsTourPaused(false);
    };
  }, [user?.uid, user?.id, user.isLoading, showAuthModal, user.loginCount]);

  const updateOnboardingInfo = (step: number, version: "v1" | "v2" | "v3") => {
    if (step <= 6) {
      localStorage.setItem("onboardingInfo", JSON.stringify({ step, version }));
      if (user.id) {
        updateDoc(doc(db, "users", user.id), {
          onboardingInfo: { step, version },
        });
      }
    }
  };

  useEffect(() => {
    if (currentStep < steps?.length && !isTourPaused) {
      const to = setTimeout(() => {
        handleTimeout(isTabletOrMobile ? 20000 : 10000);
      }, 5000);

      return () => {
        clearTimeout(to);
      };
    }
  }, [currentStep, isTourPaused, steps]);

  const handleTimeout = (delay: number) => {
    addClosingAnimationDelay(() => {
      setIsTourPaused(true);
      setTimerState((prevTimer) => {
        if (prevTimer?.type !== "skip") {
          if (prevTimer?.timer) clearTimeout(prevTimer.timer);
          return {
            timer: setTimeout(() => {
              openSiderBarTabIfNoneIsOpen(showNextTooltip);
            }, delay),
            type: delay === 10000 ? "other" : "skip",
          };
        }
      });
    });
  };

  const waitForSidebarTabToOpen = (callback) => {
    setTimeout(() => {
      callback();
    }, 10);
  };

  const showNextTooltip = () => {
    setCurrentStep((step) => {
      updateOnboardingInfo(step + 2, stepsVersion);
      return step + 1;
    });
    setIsTourPaused(false);
    setTimerState(undefined);
  };

  const openSiderBarTabIfNoneIsOpen = (callback) => {
    setActiveTab((tab) => {
      if (!tab) {
        waitForSidebarTabToOpen(callback);
        return exploratoryGroupMap.homePriceAndAffordability;
      }
      callback();
      return tab;
    });
  };

  const addClosingAnimationDelay = (callback) => {
    if (ref.current) {
      ref.current!.style.opacity = 0;
      setTimeout(callback, 1000);
    }
  };

  const handleNext = () => {
    handleTimeout(0);
  };

  return (
    mount && (
      <JoyRide
        key={`${steps?.length}`}
        tooltipComponent={(props) => (
          <TooltipComponent
            handleNext={handleNext}
            handleTimeout={handleTimeout}
            {...props}
          />
        )}
        stepIndex={currentStep}
        floaterProps={{
          getPopper: (popper, floater) => {
            if (floater === "floater") {
              ref.current = popper.instance.popper;
            }
          },
          styles: {
            arrow: { spread: 16, length: 8, color: theme.colors.primary },
            floater: {
              transition: "opacity 1s ease-in-out",
              zIndex: 1000,
            },
          },
          disableAnimation: true,
        }}
        styles={{
          options: {
            arrowColor: theme.colors.primary,
            overlayColor: "none",
          },
        }}
        disableOverlay
        disableOverlayClose
        disableCloseOnEsc
        css={{ position: "absolute" }}
        steps={steps || []}
        run={!isTourPaused}
      />
    )
  );
};
