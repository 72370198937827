// Dependencies
import { Tooltip } from "antd";
import { Flex } from "components/Flex";
import useFeedbackPopUp from "hooks/useFeedbackPopUp";
import { ResponsiveContext } from "lib/context";
import Image from "next/image";
import ChatIcon from "public/chat-icon.svg";
import { useContext } from "react";

export default function FeedbackButton(): JSX.Element {
  const { isTabletOrMobile } = useContext(ResponsiveContext);
  const { showFeedbackPopup } = useFeedbackPopUp();

  return (
    <Flex
      css={(theme) => ({
        position: "absolute",
        bottom: isTabletOrMobile ? 60 : 184,
        right: 10,
        height: 50,
        width: 105,
        borderRadius: theme.radii.small,
        backgroundColor: theme.colors.white,
        zIndex: 100,
        cursor: "pointer",
        transition: "width 0.3s ease",
        overflow: "hidden",
        whiteSpace: "nowrap",
      })}
      onClick={() => showFeedbackPopup()}
    >
      <Flex
        align="center"
        wrap="nowrap"
        gap={6}
        css={(theme) => ({
          height: "100%",
          width: "100%",
          paddingLeft: 6,
          display: "flex",

          ":hover": {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
          },
        })}
      >
        <Image
          src={ChatIcon}
          alt="Feedback"
          style={{ width: 20, height: 20, display: "inline" }}
        />
        <div
          className="feedback-text"
          css={{
            transition: "opacity 0.3s ease",
            display: "inline",
            opacity: 1,
          }}
        >
          Feedback
        </div>
      </Flex>
    </Flex>
  );
}
