import { useTheme } from "@emotion/react";
import { Flex } from "components/Flex";
import { useOutsideClick } from "hooks/useOutsideClick";
import Image from "next/image";
import { Dispatch, ReactNode, SetStateAction, useRef } from "react";
import { DrawerTypes } from "./drawerTypes";

interface Props {
  title?: string;
  show: boolean;
  excludedOutsideClick?: string[];
  setShow: Dispatch<SetStateAction<DrawerTypes | null>>;
  onClose?(): void;
  sideItem?: ReactNode;
  children: ReactNode;
}

const Drawer = ({
  title,
  show,
  setShow,
  sideItem,
  excludedOutsideClick,
  onClose,
  children,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  useOutsideClick(ref, () => closeDrawer(), excludedOutsideClick, show);

  function closeDrawer() {
    setShow(null);
    if (onClose) onClose();
  }

  return (
    <div
      ref={(r) => {
        if (r) ref.current = r;
      }}
      css={{
        background: "white",
        width: `calc(100% - 320px)`,
        height: show ? `calc(100% - 97px)` : 0,
        overflow: "scroll",
        transition: "height 0.3s ease",
        position: "absolute",
        bottom: 0,
        right: 20,
        zIndex: 2000,

        msOverflowStyle: "none",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Flex
        justify="space-between"
        align="center"
        css={{
          position: "sticky",
          top: 0,
          left: 0,

          height: 71,
          padding: "0 20px",
          borderBottom: `solid 1px ${theme.colors.inputBorder}`,
          backgroundColor: theme.colors.white,
          zIndex: 1,
        }}
      >
        <span
          css={{
            fontSize: theme.fontSizes.heading1,
            fontWeight: theme.fontWeights.bold,
          }}
        >
          {title}
        </span>

        <Flex justify="center" align="center" gap={theme.gap[6]}>
          {sideItem}
          <Flex
            css={(theme) => ({
              cursor: "pointer",
              borderRadius: theme.radii.small,
            })}
          >
            <Image
              alt="close"
              src={"/cross.svg"}
              width={37}
              height={37}
              onClick={() => closeDrawer()}
            />
          </Flex>
        </Flex>
      </Flex>
      {children}
    </div>
  );
};

export default Drawer;
