import { mdiPlayCircle } from "@mdi/js";
import Icon from "@mdi/react";
import { Text } from "components/Text";
import { TourContext } from "components/provider/TourProvider";
import { ResponsiveContext } from "lib/context";
import { useContext } from "react";
import { Step } from "react-joyride";

export const useTour = () => {
  const { isTabletOrMobile } = useContext(ResponsiveContext);
  const onboardingSteps = {
    v1: [
      {
        target: "#geo-search-icon",
        title: "Search over areas across America",
        disableBeacon: true,
        placement: "bottom-start",
      },

      ...(isTabletOrMobile
        ? [
            {
              target: "#change-datapoint-dropdown",
              title: "Explore other Data Points",
              disableBeacon: true,
              placement: isTabletOrMobile ? "bottom" : "right-start",
            },
          ]
        : [
            {
              target: "#home_value-datapoint-radio",
              title: "Explore other Data Points",
              disableBeacon: true,
              placement: isTabletOrMobile ? "bottom" : "right-start",
            },
            {
              target: "#over_under_valued_percentage-premium-datapoint-radio",
              title:
                "Premium Data Points show how Over or Undervalued Home Prices and how much they could go up or down.",
              disableBeacon: true,
              placement: isTabletOrMobile ? "bottom" : "right-start",
              offset: 25,
            },

            {
              target: ".mapboxgl-ctrl-icon",
              title: "Zoom in and zoom out to get the better and detailed view",
              disableBeacon: true,
              placement: "left-end",
              offset: -10,
            },
            {
              target: "#map-tour-tooltip",
              title:
                "Click on the map to get detailed graph of home value in your area",
              disableBeacon: true,
              placement: "top",
              offset: 25,
            },
            {
              target: "#geo-state-radio",
              title:
                "Change geographic View and Zoom out to see Real Estate across America",
              disableBeacon: true,
              placement: isTabletOrMobile ? "top-start" : "bottom-start",
            },
          ]),
    ] as Step[],
    v2: [
      ...(isTabletOrMobile
        ? [
            {
              target: "#change-datapoint-dropdown",
              title: "Explore other Data Points",
              disableBeacon: true,
              placement: isTabletOrMobile ? "bottom" : "right-start",
            },
          ]
        : [
            {
              target: "#home_value-datapoint-radio",
              title: "Explore other Data Points",
              disableBeacon: true,
              placement: isTabletOrMobile ? "bottom" : "right-start",
            },
            {
              target: "#over_under_valued_percentage-premium-datapoint-radio",
              title:
                "Premium Data Points show how Over or Undervalued Home Prices and how much they could go up or down.",
              disableBeacon: true,
              placement: isTabletOrMobile ? "bottom" : "right-start",
              offset: 25,
            },
            {
              target: "#map-tour-tooltip",
              title:
                "Click on the map to get detailed graph of home value in your area",
              disableBeacon: true,
              placement: "top",
              offset: 25,
            },
            {
              target: "#geo-state-radio",
              title:
                "Change geographic View and Zoom out to see Real Estate across America",
              disableBeacon: true,
              placement: "bottom-start",
            },
          ]),
      {
        target: "#geo-search-icon",
        title: "Search over areas across America",
        disableBeacon: true,
        placement: isTabletOrMobile ? "top-start" : "bottom-start",
      },
    ] as Step[],
    v3: [
      {
        target: "#geo-search-icon",
        title: "Search Real Estate in a Different Area",
        disableBeacon: true,
        placement: "bottom-start",
      },
      ...(isTabletOrMobile
        ? []
        : [
            {
              target: "#geo-metro-radio",
              title: "Click Metro to see Real Estate Data in other Cities",
              disableBeacon: true,
              placement: isTabletOrMobile ? "top-start" : "bottom-start",
            },
            {
              target: "#geo-zip-radio",
              title:
                "Search Real Estate Data in specific ZIP Codes and Neighborhoods",
              disableBeacon: true,
              placement: isTabletOrMobile ? "top-end" : "bottom-start",
            },
          ]),
      {
        target: "#mom_home_value_growth-datapoint-radio",
        title: "Change Data Point",
        disableBeacon: true,
        placement: isTabletOrMobile ? "bottom" : "right-start",
      },
    ] as Step[],
  };

  return { ...useContext(TourContext), onboardingSteps };
};
