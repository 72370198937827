import { useTheme } from "@emotion/react";
import { Button, Col, Collapse, Input, Row, Tooltip } from "antd";
import { Flex } from "components/Flex";
import PenalChevron from "components/exploratory/PenalChevron";
import PremiumIcon from "components/icons/PremiumIcon";
import SearchIcon from "components/icons/SearchIcon";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import useGeoExploratory, {
  GroupedExploratoryOption,
} from "hooks/useGeoExploratory";
import { useUserData } from "hooks/useUser";
import {
  getSourcesTextForCode,
  getSummaryForCode,
} from "lib/helpers/exploratoryHelpers";
import { exploratoryGroupMap } from "lib/options/exploratory";
import { values } from "lodash";
import Image from "next/image";
import PremiumIconSvg from "public/premium-icon.svg";
import { ChangeEventHandler, useContext, useEffect, useState } from "react";
import YTPlayer from "yt-player";
import Drawer from "../Drawer";
import { DrawerTypes } from "../drawerTypes";

const { Panel } = Collapse;

const BORDER_BOTTOM = "1px solid rgba(0, 0, 0, 0.07)";

const DatapointsDrawer = ({ show, setShow }) => {
  const theme = useTheme();
  const user = useUserData();

  const { groupedExploratories } = useGeoExploratory();
  const { setShowPlanModal, setPlanPopupTrigger } =
    useContext(PlanModalContext);

  const [panelGroupsData, setPanelGroupsData] = useState(groupedExploratories);
  const [searchValue, setSearchValue] = useState("");
  const [activeTabs, setActiveTabs] = useState(values(exploratoryGroupMap));
  const [players, setPLayers] = useState<YTPlayer[]>([]);

  const onSearchInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const inputValue = e.target.value;

    setSearchValue(inputValue);

    const groups = groupedExploratories.reduce((acc, group) => {
      const newGroup = {
        label: group.label,
        options: inputValue
          ? group.options.filter((option) =>
              option.label.toLowerCase().startsWith(inputValue.toLowerCase()),
            )
          : group.options,
      };

      if (!newGroup.options.length) {
        return acc;
      }

      acc.push(newGroup);
      return acc;
    }, [] as GroupedExploratoryOption[]);

    setPanelGroupsData(groups);
  };

  useEffect(() => {
    if (show) {
      initVideos();
    }

    return () => {
      players.forEach((player) => {
        player.pause();
      });
    };
  }, [show]);

  function initVideos() {
    groupedExploratories.forEach((group) => {
      group.options.forEach((option) => {
        const video = new YTPlayer(`.ytv-${option.value}`, {
          captions: false,
          annotations: false,
          controls: false,
          keyboard: false,
          modestBranding: true,
          related: false,
        });
        setPLayers((prev) => {
          prev.push(video);
          return prev;
        });

        video.load(option.ytVideoCode);

        video.on("playing", () => {
          players.forEach((player) => {
            if (player.videoId !== video.videoId) {
              player.pause();
            }
          });
        });
      });
    });
  }

  return (
    <Drawer
      title="Data Points"
      show={show === DrawerTypes.DATAPOINTS}
      setShow={setShow}
      sideItem={
        <Input
          value={searchValue}
          bordered={false}
          onChange={onSearchInputChange}
          placeholder="SEARCH DATA POINTS"
          prefix={<SearchIcon width={17} height={17} />}
          css={(theme) => ({
            display: "flex",
            alignItems: "center",
            fontSize: theme.fontSizes.small,
            height: 41,
            width: 288,
            borderRadius: theme.radii.small,
            border: "1px solid rgba(146, 146, 146, 0.31)",
            boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.12)",
            backgroundColor: "rgba(255, 255, 255, 0.36)",

            ":hover": {
              border: `1px solid ${theme.colors.primary}`,
            },
          })}
        />
      }
    >
      <div
        css={{
          padding: `12px 40px`,
        }}
      >
        <Collapse
          activeKey={activeTabs}
          ghost
          onChange={(e) => {
            setActiveTabs(e as string[]);
          }}
          css={{
            ".ant-collapse-header": {
              padding: "12px 0 !important",
              alignItems: "center !important",
              borderBottom: BORDER_BOTTOM,
            },
            ".ant-collapse-extra span": {
              overflow: "visible !important",
            },
            ".ant-radio-wrapper": {
              width: "100%",
            },
            ".ant-radio + *": {
              paddingRight: "0px !important",
              width: "100%",
            },
            ".ant-radio-group, .ant-space": {
              width: "100%",
            },
            ".ant-collapse-content>.ant-collapse-content-box": {
              padding: `24px 0 !important`,
            },
          }}
        >
          {panelGroupsData?.map((group) => {
            if (!group.options?.length) return null;

            return (
              <Panel
                header={
                  <span
                    css={{
                      fontSize: theme.fontSizes.larger,
                      fontWeight: theme.fontWeights.bold,
                    }}
                  >
                    {group.label}
                  </span>
                }
                key={group.label}
                showArrow={false}
                extra={
                  <PenalChevron active={activeTabs.includes(group.label)} />
                }
              >
                {
                  <Row gutter={[90, 30]}>
                    {group.options.map((option) => {
                      return (
                        <Col key={option.value} span={12}>
                          <Flex gap={20} align="center">
                            {
                              <div
                                css={{
                                  aspectRatio: "9/16",
                                  height: 300,
                                }}
                              >
                                <div
                                  className={`ytv-${option.value}`}
                                  css={{
                                    width: "100%",
                                    height: "100%",
                                    border: "none",
                                    borderRadius: theme.radii.small,
                                  }}
                                />
                              </div>
                            }
                            <Flex direction="column">
                              <Flex
                                align="center"
                                css={{
                                  fontWeight: theme.fontWeights.bold,
                                  fontSize: theme.fontSizes.large,
                                }}
                              >
                                {option.label}
                                {option.premium && !user.isPremiumOrBasic ? (
                                  <Tooltip
                                    placement="bottom"
                                    trigger="hover"
                                    destroyTooltipOnHide
                                    color={theme.colors.black}
                                    zIndex={2000}
                                    overlayInnerStyle={{
                                      borderRadius: theme.radii.small,
                                    }}
                                    title={
                                      <Flex
                                        direction="column"
                                        gap={7}
                                        css={{
                                          padding: "9.5px 13px",
                                          width: 175,
                                        }}
                                      >
                                        <Image
                                          src={PremiumIconSvg}
                                          alt="premium"
                                          width={27}
                                          height={25}
                                        />
                                        <span
                                          css={{
                                            color: theme.colors.white,
                                            fontSize: 12,
                                            fontWeight: 400,
                                          }}
                                        >
                                          This is a premium datapoint upgrade to
                                          see insights
                                        </span>
                                        <Button
                                          onClick={() => {
                                            setPlanPopupTrigger(
                                              `Upgrade to see insight: ${option.value}`,
                                            );
                                            setShowPlanModal(true);
                                          }}
                                          type="primary"
                                          css={{
                                            fontWeight: theme.fontWeights.bold,
                                          }}
                                        >
                                          Upgrade
                                        </Button>
                                      </Flex>
                                    }
                                  >
                                    <span
                                      css={{
                                        transform: "scale(1.3)",
                                        ":hover": { cursor: "pointer" },
                                      }}
                                    >
                                      <PremiumIcon />
                                    </span>
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                              </Flex>

                              <span css={{ fontSize: theme.fontSizes.small }}>
                                {getSummaryForCode(option.value)}
                              </span>

                              <span>
                                Source:{" "}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: getSourcesTextForCode(
                                      option.value,
                                    ) as string,
                                  }}
                                />
                              </span>
                            </Flex>
                          </Flex>
                        </Col>
                      );
                    })}
                  </Row>
                }
              </Panel>
            );
          })}
        </Collapse>
      </div>
    </Drawer>
  );
};

export default DatapointsDrawer;
