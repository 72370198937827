/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import { metroZillowMap, stateMap } from "lib/constants";
import { getMapDisplayValue, sanitizeGeoName } from "lib/helpers";
import { getScoreRangeLevel } from "lib/helpers/scoreHelpers";
import scoreConfigs from "lib/scoreBoard/scoreConfigs";
import scoreDatapointsMap from "lib/scoreBoard/scoreDatapointsMap";
import { keys } from "lodash";
import moment from "moment";
import { Fragment, useMemo } from "react";
import { Geo } from "types/MapContext";
import { FeatureState } from "types/Mapbox";
import { Datapoints } from "types/cube";
import { IFilter } from "types/options";
import { Flex } from "./Flex";
import { Text } from "./Text";
import ArrowLeft from "./icons/ArrowLeft";
import { Button } from "antd";
import useGeoSearch from "hooks/useGeoSearch";

const formatHistoricDate = (date) => moment(date).format("MMM. YYYY:");
const formatDateToYear = (date) => moment(date).format("YYYY:");

const HoverPinDisabled = ({
  info,
  handleClicked,
}: {
  info: {
    state: FeatureState;
    geo: Geo;
    exploratory: string;
    STUSPS: string;
    NAME: string;
    exploratoryDisplayValue: string;
    label: string;
    properties: any;
    lng: number;
    lat: number;
    detailedClick: any;
    selectedFilters: IFilter[];
    geoContext: any;

    [key: string]: any;
  };
  handleClicked: () => void;
}) => {
  const theme = useTheme();
  const { geo: selectedGeo } = useGeoSearch();
  const geo = info?.geo || selectedGeo;
  const numericValue = +(info?.exploratoryDisplayValue || 0);
  const scoreLevel = getScoreRangeLevel(
    numericValue / 100,
    info.exploratory as any,
  );
  const isScore = useMemo(() => {
    return keys(scoreDatapointsMap).some((key) => key === info.exploratory);
  }, [info.exploratory]);

  const calculateString = (value = 0, exploratory: any = undefined) => {
    return getMapDisplayValue({ exploratory, value });
  };

  const renderName = () => {
    switch (geo) {
      case Geo.ZIP:
        return sanitizeGeoName(info.state?.GEOID);
      case Geo.COUNTY:
        return `${info.properties?.BASENAME}, ${stateMap.find(
          (state) => state.stateCode == info.state?.STATE,
        )?.stateAbbr}`;
      case Geo.METRO:
        return `${info.properties?.BASENAME}, ${metroZillowMap.find(
          (metro) => metro?.["CBSA Code"] === parseInt(info.properties?.GEOID),
        )?.State}`;
      case Geo.STATE:
        return info.NAME.length > 9 ? info.STUSPS : info.NAME;
      default:
        return info.NAME.length > 9 ? info.STUSPS : info.NAME;
    }
  };

  const metaDataMap = {
    [Datapoints.HOME_VALUE_GROWTH_RATE]: {
      type: "multiLine",
      startDate: formatHistoricDate(
        info.state?.home_value_growth_rate_tooltip_home_value_prev_date,
      ),
      startValue: calculateString(
        info.state?.home_value_growth_rate_tooltip_home_value_prev_value,
        Datapoints.HOME_VALUE,
      ),
      endDate: formatHistoricDate(
        info.state?.home_value_growth_rate_tooltip_home_value_latest_date,
      ),
      endValue: calculateString(
        info.state?.home_value_growth_rate_tooltip_home_value_latest_value,
        Datapoints.HOME_VALUE,
      ),
    },
    [Datapoints.HOME_VALUE_FIVE_YEAR_GROWTH_RATE]: {
      type: "multiLine",
      startDate: formatHistoricDate(
        info.state
          ?.home_value_five_year_growth_rate_tooltip_home_value_prev_date,
      ),
      startValue: calculateString(
        info.state
          ?.home_value_five_year_growth_rate_tooltip_home_value_prev_value,
        Datapoints.HOME_VALUE,
      ),
      endDate: formatHistoricDate(
        info.state
          ?.home_value_five_year_growth_rate_tooltip_home_value_latest_date,
      ),
      endValue: calculateString(
        info.state
          ?.home_value_five_year_growth_rate_tooltip_home_value_latest_value,
        Datapoints.HOME_VALUE,
      ),
    },

    [Datapoints.RENT_GROWTH_YOY]: {
      type: "multiLine",
      startDate: formatHistoricDate(
          info.state?.rent_growth_yoy_tooltip_rent_for_apartments_prev_date,
      ),
      startValue: calculateString(
          info.state?.rent_growth_yoy_tooltip_rent_for_apartments_prev_value,
          Datapoints.RENT_GROWTH_YOY,
      ),
      endDate: formatHistoricDate(
          info.state?.rent_growth_yoy_tooltip_rent_for_apartments_latest_date,
      ),
      endValue: calculateString(
          info.state?.rent_growth_yoy_tooltip_rent_for_apartments_latest_value,
          Datapoints.RENT_GROWTH_YOY,
      ),
    },

    [Datapoints.POVERTY_PERCENTAGE]: {
      type: "multiLine",
      startDate: `Population living in poverty:`,
      startValue: `${calculateString(
        info.state?.poverty_percentage_tooltip_population_living_in_poverty,
        Datapoints.POPULATION,
      )}`,
      endDate: `Population:`,
      endValue: `${calculateString(
        info.state?.poverty_percentage_tooltip_population,
        Datapoints.POPULATION,
      )}`,
    },
    [Datapoints.HOME_OWNERSHIP_RATE]: {
      type: "multiLine",
      startDate: `Ownership HH's:`,
      startValue: `${calculateString(
        info.state?.home_ownership_rate_tooltip_ownership_hhs,
        Datapoints.HOUSEHOLDS,
      )}`,
      endDate: `HH's:`,
      endValue: `${calculateString(
        info.state?.home_ownership_rate_tooltip_households,
        Datapoints.HOUSEHOLDS,
      )}`,
    },
    [Datapoints.HOME_VALUE_TO_EARNINGS_RATIO]: {
      type: "multiLine",
      startDate: `Home Value:`,
      startValue: `${calculateString(
        info.state?.home_value_to_earnings_ratio_tooltip_home_value,
        Datapoints.HOME_VALUE,
      )}`,
      endDate: `Med. HH income:`,
      endValue: `${calculateString(
        info.state
          ?.home_value_to_earnings_ratio_tooltip_median_household_income,
        Datapoints.HOME_VALUE,
      )}`,
    },
    [Datapoints.PROPERTY_TAX_RATE]: {
      type: "multiLine",
      startDate: "Property tax:",
      startValue: `$${Math.round(
        info.state?.home_value * (info.state?.property_tax_rate / 100),
      )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      endDate: "Home Value:",
      endValue: `${calculateString(
        info.state?.home_value,
        Datapoints.HOME_VALUE,
      )}`,
    },
    [Datapoints.CAP_RATE]: {
      type: "multiLine",
      startDate: "Gross Rent Income:",
      startValue: `${calculateString(
        info.state?.cap_rate_tooltip_gross_rent_income,
        Datapoints.HOME_VALUE,
      )}`,
      endDate: "Expenses:",
      endValue: `${calculateString(
        info.state?.cap_rate_tooltip_expenses,
        Datapoints.HOME_VALUE,
      )}`,
      extra: [
        {
          label: "Net Rent Income:",
          value: `${calculateString(
            info.state?.cap_rate_tooltip_net_rent_income,
            Datapoints.HOME_VALUE,
          )}`,
        },
        {
          label: "Home Value:",
          value: `${calculateString(
            info.state?.cap_rate_tooltip_home_value,
            Datapoints.HOME_VALUE,
          )}`,
        },
      ],
    },
    [Datapoints.POPULATION_GROWTH_RATE]: {
      type: "multiLine",
      startDate: formatDateToYear(
        info.state?.population_growth_rate_tooltip_population_prev_date,
      ),
      startValue: calculateString(
        info.state?.population_growth_rate_tooltip_population_prev_value,
        Datapoints.POPULATION,
      ),
      endDate: formatDateToYear(
        info.state?.population_growth_rate_tooltip_population_latest_date,
      ),
      endValue: calculateString(
        info.state?.population_growth_rate_tooltip_population_latest_value,
        Datapoints.POPULATION,
      ),
    },
    [Datapoints.MEDIAN_INCOME_GROWTH_RATE]: {
      type: "multiLine",
      startDate: formatDateToYear(
        info.state
          .median_income_growth_rate_tooltip_median_household_income_prev_date,
      ),
      startValue: calculateString(
        info.state
          ?.median_income_growth_rate_tooltip_median_household_income_prev_value,
        Datapoints.HOME_VALUE,
      ),
      endDate: formatDateToYear(
        info.state
          .median_income_growth_rate_tooltip_median_household_income_latest_date,
      ),
      endValue: calculateString(
        info.state
          ?.median_income_growth_rate_tooltip_median_household_income_latest_value,
        Datapoints.HOME_VALUE,
      ),
    },
    [Datapoints.HOUSING_UNIT_GROWTH]: {
      type: "multiLine",
      startDate: formatDateToYear(
        info.state?.housing_unit_growth_tooltip_housing_units_prev_date,
      ),
      startValue: `${calculateString(
        info.state?.housing_unit_growth_tooltip_housing_units_prev_value,
        Datapoints.HOUSING_UNITS,
      )}`,
      endDate: formatDateToYear(
        info.state?.housing_unit_growth_tooltip_housing_units_latest_date,
      ),
      endValue: `${calculateString(
        info.state?.housing_unit_growth_tooltip_housing_units_latest_value,
        Datapoints.HOUSING_UNITS,
      )}`,
    },
    [Datapoints.ABSENTEE_OWNER_PERCENTAGE]: {
      type: "multiLine",
      startDate: "Absentee Owned Units:",
      startValue: calculateString(
        info.state?.absentee_owner_percentage_tooltip_absentee_owned_units,
        Datapoints.ABSENTEE_OWNED_UNITS,
      ),
      endDate: `Ownerships HH's + Shadow Inventory:`,
      endValue: calculateString(
        info.state?.absentee_owner_percentage_tooltip_ownership_hhs +
          info.state?.absentee_owner_percentage_tooltip_absentee_owned_units,
        Datapoints.ABSENTEE_OWNED_UNITS,
      ),
    },
    [Datapoints.MORTGAGED_HOME_PERCENTAGE]: {
      type: "multiLine",
      startDate: "Mortgaged Homes:",
      startValue: calculateString(
        info.state
          ?.mortgaged_home_percentage_tooltip_housing_units_with_a_mortgage,
        Datapoints.HOUSEHOLDS,
      ),
      endDate: "Ownership HHs:",
      endValue: calculateString(
        info.state?.mortgaged_home_percentage_tooltip_ownership_hhs,
        Datapoints.HOUSEHOLDS,
      ),
    },
    [Datapoints.SHARE_OF_LISTINGS_WITH_PRICE_CUT]: {
      type: "multiLine",
      startDate: "Listings with price cut:",
      startValue: `${calculateString(
        info.state
          ?.share_of_listings_with_price_cut_tooltip_listings_with_price_cut,
        Datapoints.TOTAL_ACTIVE_INVENTORY,
      )}`,
      endDate: "Total inventory:",
      endValue: `${calculateString(
        info.state?.share_of_listings_with_price_cut_tooltip_total_inventory,
        Datapoints.TOTAL_ACTIVE_INVENTORY,
      )}`,
    },
    [Datapoints.TYPICAL_HOUSE_PAYMENT]: {
      type: "multiLine",
      startDate: `Principal & Interest`,
      startValue: calculateString(
        info.state?.typical_house_payment_tooltip_principle_interest,
        Datapoints.HOME_VALUE,
      ),
      endDate: `Insurance, Taxes and other costs:`,
      endValue: calculateString(
        info.state?.typical_house_payment_tooltip_insurance_taxes_misc_costs,
        Datapoints.HOME_VALUE,
      ),
    },
    [Datapoints.SALARY_TO_AFFORD_HOUSE]: {
      type: "multiLine",
      startDate: `Mortgage Payment (Monthly):`,
      startValue: calculateString(
        info.state?.salary_to_afford_house_tooltip_typical_house_payment,
        Datapoints.TYPICAL_HOUSE_PAYMENT,
      ),
      endDate: `Mortgage Payment (Annual):`,
      endValue: calculateString(
        info.state?.salary_to_afford_house_tooltip_typical_yearly_house_payment,
        Datapoints.TYPICAL_HOUSE_PAYMENT,
      ),
    },
    [Datapoints.HOUSE_PAYMENT_RATE_OF_MED_INCOME]: {
      type: "multiLine",
      startDate: `Typical Yearly House Payment:`,
      startValue: calculateString(
        info.state
          .house_payment_rate_of_med_income_tooltip_typical_yearly_house_payment,
        Datapoints.HOME_VALUE,
      ),
      endDate: `Median Household Income:`,
      endValue: calculateString(
        info.state
          ?.house_payment_rate_of_med_income_tooltip_median_household_income,
        Datapoints.HOME_VALUE,
      ),
    },
    [Datapoints.MIGRATION_PERCENTAGE]: {
      type: "multiLine",
      startDate: `Migration Total:`,
      startValue: calculateString(
        info.state?.migration_percentage_tooltip_total_migration,
        Datapoints.TOTAL_MIGRATION,
      ),
      endDate: `Population:`,
      endValue: calculateString(
        info.state
          ?.building_permit_percentage_latest_tooltip_migration_population,
        Datapoints.POPULATION,
      ),
    },
    [Datapoints.OVER_UNDER_VALUED_PERCENTAGE]: {
      type: "multiLine",
      startDate: `Typical Home Price:`,
      startValue: calculateString(
        info.state?.over_under_valued_percentage_tooltip_home_value,
        Datapoints.HOME_VALUE,
      ),
      endDate: `Fair Home Value:`,
      endValue: calculateString(
        info.state?.over_under_valued_percentage_tooltip_fair_home_value,
        Datapoints.HOME_VALUE,
      ),
    },
    [Datapoints.PERCENT_CRASH_GREAT_RECESSION]: {
      type: "multiLine",
      startDate: `2007 Home Price:`,
      startValue: calculateString(
        info.state?.percent_crash_great_recession_tooltip_years_home_value_2007,
        Datapoints.HOME_VALUE,
      ),
      endDate: `2012 Home Price:`,
      endValue: calculateString(
        info.state?.percent_crash_great_recession_tooltip_years_home_value_2012,
        Datapoints.HOME_VALUE,
      ),
    },
    [Datapoints.BUY_VERSUS_RENT_PERCENTAGE]: {
      type: "multiLine",
      startDate: `Typical House Payment:`,
      startValue: calculateString(
        info.state
          ?.buy_versus_rent_percentage_tooltip_typical_yearly_house_payment,
        Datapoints.HOME_VALUE,
      ),
      endDate: `Apartment Rent:`,
      endValue: calculateString(
        info.state?.buy_versus_rent_percentage_tooltip_yearly_apartment_rent,
        Datapoints.HOME_VALUE,
      ),
    },
    [Datapoints.YOY_INVENTORY_CHANGE_PERCENTAGE]: {
      type: "multiLine",
      startDate: formatHistoricDate(
        info.state
          .yoy_inventory_change_percentage_tooltip_total_active_inventory_prev_date,
      ),
      startValue: `${calculateString(
        info.state
          .yoy_inventory_change_percentage_tooltip_total_active_inventory_prev_value,
        Datapoints.TOTAL_ACTIVE_INVENTORY,
      )}`,
      endDate: formatHistoricDate(
        info.state
          .yoy_inventory_change_percentage_tooltip_total_active_inventory_latest_date,
      ),
      endValue: `${calculateString(
        info.state
          .yoy_inventory_change_percentage_tooltip_total_active_inventory_latest_value,
        Datapoints.TOTAL_ACTIVE_INVENTORY,
      )}`,
    },
    [Datapoints.INVENTORY_PERCENTAGE_OF_HOUSES]: {
      type: "multiLine",
      startDate: `For Sale Inventory:`,
      startValue: `${calculateString(
        info.state
          ?.inventory_percentage_of_houses_tooltip_total_active_inventory,
        Datapoints.TOTAL_ACTIVE_INVENTORY,
      )}`,
      endDate: `Absentee Owned + Homeowner HH's:`,
      endValue: `${calculateString(
        info.state
          ?.inventory_percentage_of_houses_tooltip_absentee_owned_units +
          info.state?.inventory_percentage_of_houses_tooltip_ownership_hhs,
        Datapoints.TOTAL_ACTIVE_INVENTORY,
      )}`,
    },
    [Datapoints.RENT_PERCENTAGE_OF_INCOME]: {
      type: "multiLine",
      startDate: `Yearly Apartment Rent:`,
      startValue: `${calculateString(
        info.state?.rent_percentage_of_income_tooltip_yearly_apartment_rent,
        Datapoints.HOME_VALUE,
      )}`,
      endDate: `Med. HH Income:`,
      endValue: `${calculateString(
        info.state?.rent_percentage_of_income_tooltip_median_household_income,
        Datapoints.HOME_VALUE,
      )}`,
    },
    [Datapoints.MOM_HOME_VALUE_GROWTH]: {
      type: "multiLine",
      startDate: moment(
        info.state?.mom_home_value_growth_tooltip_home_value_prev_date,
      ).format("M/DD/YYYY:"),
      startValue: `${calculateString(
        info.state?.mom_home_value_growth_tooltip_home_value_prev_value,
        Datapoints.HOME_VALUE,
      )}`,
      endDate: moment(
        info.state?.mom_home_value_growth_tooltip_home_value_latest_date,
      ).format("M/DD/YYYY:"),
      endValue: `${calculateString(
        info.state?.mom_home_value_growth_tooltip_home_value_latest_value,
        Datapoints.HOME_VALUE,
      )}`,
    },
    [Datapoints.YOY_DAYS_ON_MARKET]: {
      type: "multiLine",
      startDate: formatHistoricDate(
        info.state?.yoy_days_on_market_tooltip_days_on_market_prev_date,
      ),
      startValue: `${calculateString(
        info.state?.yoy_days_on_market_tooltip_days_on_market_prev_value,
        Datapoints.TOTAL_ACTIVE_INVENTORY,
      )}`,
      endDate: formatHistoricDate(
        info.state?.yoy_days_on_market_tooltip_days_on_market_latest_date,
      ),
      endValue: `${calculateString(
        info.state?.yoy_days_on_market_tooltip_days_on_market_latest_value,
        Datapoints.TOTAL_ACTIVE_INVENTORY,
      )}`,
    },
    [Datapoints.MOM_ACTIVE_INVENTORY_CHANGE_PERCENTAGE]: {
      type: "multiLine",
      startDate: `Current Month Inventory:`,
      startValue: `${calculateString(
        info.state
          ?.mom_active_inventory_change_percentage_tooltip_total_active_inventory_latest_value,
        Datapoints.TOTAL_ACTIVE_INVENTORY,
      )}`,
      endDate: `Prev. Month Inventory:`,
      endValue: `${calculateString(
        info.state
          ?.mom_active_inventory_change_percentage_tooltip_total_active_inventory_prev_value,
        Datapoints.TOTAL_ACTIVE_INVENTORY,
      )}`,
    },
    [Datapoints.PER_CHANGE_HOME_VALUE_JUNE]: {
      type: "multiLine",
      startDate: `Current Month Home Price:`,
      startValue: `${calculateString(
        info.state?.per_change_home_value_june_tooltip_home_value,
        Datapoints.HOME_VALUE,
      )}`,
      endDate: `June 2022 Home Price:`,
      endValue: `${calculateString(
        info.state?.per_change_home_value_june_tooltip_dates_home_value_6_2022,
        Datapoints.HOME_VALUE,
      )}`,
    },
    [Datapoints.MEDIAN_LISTING_PRICE_YOY]: {
      type: "multiLine",
      startDate: formatHistoricDate(
        info.state
          .median_listing_price_yoy_tooltip_median_listing_price_latest_date,
      ),
      startValue: `${calculateString(
        info.state
          .median_listing_price_yoy_tooltip_median_listing_price_latest_value,
        Datapoints.TOTAL_ACTIVE_INVENTORY,
      )}`,
      endDate: formatHistoricDate(
        info.state
          .median_listing_price_yoy_tooltip_median_listing_price_prev_date,
      ),
      endValue: `${calculateString(
        info.state
          .median_listing_price_yoy_tooltip_median_listing_price_prev_value,
        Datapoints.TOTAL_ACTIVE_INVENTORY,
      )}`,
    },
    [Datapoints.NEW_LISTING_COUNT_YOY]: {
      type: "multiLine",
      startDate: formatHistoricDate(
        info.state.new_listing_count_yoy_tooltip_new_listing_count_latest_date,
      ),
      startValue: `${calculateString(
        info.state.new_listing_count_yoy_tooltip_new_listing_count_latest_value,
        Datapoints.TOTAL_ACTIVE_INVENTORY,
      )}`,
      endDate: formatHistoricDate(
        info.state.new_listing_count_yoy_tooltip_new_listing_count_prev_date,
      ),
      endValue: `${calculateString(
        info.state.new_listing_count_yoy_tooltip_new_listing_count_prev_value,
        Datapoints.TOTAL_ACTIVE_INVENTORY,
      )}`,
    },
    [Datapoints.FOR_SALE_INVENTORY_V_AVG]: {
      type: "multiLine",
      startDate: `For Sale Inventory:`,
      startValue: calculateString(
        info.state.for_sale_inventory_v_avg_tooltip_total_active_inventory,
        Datapoints.TOTAL_ACTIVE_INVENTORY,
      ),
      endDate: "Long Term Average:",
      endValue: calculateString(
        info.state.for_sale_inventory_v_avg_tooltip_long_term_fsi_avg,
        Datapoints.TOTAL_ACTIVE_INVENTORY,
      ),
    },
    [Datapoints.BUILDING_PERMIT_PERCENTAGE]: {
      type: "multiLine",
      startDate: `Building Permits:`,
      startValue: calculateString(
        info.state.building_permit_percentage_tooltip_building_permits,
        Datapoints.BUILDING_PERMITS,
      ),
      endDate: "Population:",
      endValue: calculateString(
        info.state
          .building_permit_percentage_latest_tooltip_migration_population,
        Datapoints.POPULATION,
      ),
    },
    [Datapoints.VACANCY_RATE]: {
      type: "multiLine",
      startDate: `Vacant Units:`,
      startValue: calculateString(
        info.state.vacancy_rate_tooltip_vacant_house,
        Datapoints.VACANT_HOUSE,
      ),
      endDate: "Housing Units:",
      endValue: calculateString(
        info.state.vacancy_rate_tooltip_housing_units,
        Datapoints.HOUSING_UNITS,
      ),
    },
    ...scoreConfigs.reduce((acc, cur) => {
      acc[cur.code] = {
        type: "multiLine",
        extra: cur.extraData?.map((extra) => ({
          label: `${extra.label}:`,
          value: calculateString(
            info.state?.[extra.code],
            Datapoints.DAYS_ON_MARKET,
          ),
        })),
      };

      return acc;
    }, {}),
  };

  const renderHoverLine = (label, value) => {
    return (
      label && (
        <Flex
          align="center"
          css={(theme) => ({
            color: theme.colors.white,
            fontSize: theme.fontSizes.small,
            paddingBottom: "4px",
            fontWeight: theme.fontWeights.body,
          })}
        >
          <span>
            {label === "Home Value Growth (MoM):"
              ? "Price Growth (MoM):"
              : label}
          </span>
          <div
            style={{
              height: "13px",
              padding: "1px",
              maxWidth: "56px",
              width: "100%",
              borderRadius: "10px",
              marginLeft: "2px",
            }}
            className="map-disabled-hover-tooltip"
          >
            <div
              css={{
                maxWidth: "53px",
                background: "gray",
                borderRadius: "10px",
                height: "11px",
              }}
            ></div>
          </div>
        </Flex>
      )
    );
  };

  const renderContext = () => {
    const geoData = info?.geoContext?.features?.filter((feature) => {
      return feature.id.includes("postcode");
    });

    const geoMap = {
      place: "City",
      district: "County",
      region: "State",
    };
    if (geoData && geoData.length && geoData[0]?.context?.length) {
      return geoData[0].context.map((context, i) => {
        let contextType = "";

        if (context.id.includes("place")) {
          contextType = geoMap.place;
        }

        if (context.id.includes("district")) {
          contextType = geoMap.district;
        }
        if (context.id.includes("region")) {
          contextType = geoMap.region;
        }

        if (contextType && info.geo !== "state") {
          if (
            info.geo === "metro" &&
            (contextType === geoMap.place || contextType === geoMap.district)
          ) {
            return;
          }

          if (info.geo === "county" && contextType === geoMap.place) {
            return;
          }

          return (
            <div key={i}>
              {renderHoverLine(`${contextType}:`, context.text)}
            </div>
          );
        }
      });
    }
  };

  const renderHoverData = () => {
    if (
      info.exploratory === Datapoints.SALARY_TO_AFFORD_HOUSE ||
      info.exploratory === Datapoints.VACANCY_RATE
    ) {
      return (
        <>
          {renderHoverMetaData()}
          {renderHoverLine(`${info.label}:`, info.exploratoryDisplayValue)}
        </>
      );
    }
    return (
      <>
        {renderHoverLine(`${info.label}:`, info.exploratoryDisplayValue)}
        {renderHoverMetaData()}
      </>
    );
  };

  const renderHoverMetaData = () => {
    if (metaDataMap[info.exploratory]) {
      return (
        <>
          {metaDataMap[info.exploratory]?.type === "multiLine" ? (
            <>
              {renderHoverLine(
                metaDataMap[info.exploratory].startDate,
                metaDataMap[info.exploratory].startValue,
              )}
              {renderHoverLine(
                metaDataMap[info.exploratory].endDate,
                metaDataMap[info.exploratory].endValue,
              )}
              {metaDataMap[info.exploratory]?.tertiaryDate &&
                renderHoverLine(
                  metaDataMap[info.exploratory].tertiaryDate,
                  metaDataMap[info.exploratory].tertiaryValue,
                )}
              {metaDataMap[info.exploratory]?.extra &&
                metaDataMap[info.exploratory]?.extra.map((line, i) => (
                  <Fragment key={i}>
                    {renderHoverLine(line.label, line.value)}
                  </Fragment>
                ))}
            </>
          ) : (
            <>{renderHoverLine("Context:", metaDataMap[info.exploratory])}</>
          )}
        </>
      );
    }
  };

  return (
    <div
      css={(theme) => ({
        borderRadius: theme.radii.small,
        width: 215,
      })}
      className="hover-pin-title"
    >
      <Text
        css={(theme) => ({
          display: "flex",
          alignItems: "center",
          gap: 6,
          fontSize: theme.fontSizes.small,
          fontWeight: theme.fontWeights.body,
          marginBottom: 8,
          background: theme.colors.erieBlack,
          padding: `${theme.padding.small}px ${theme.padding.default}px`,
          borderTopLeftRadius: theme.radii.small,
          borderTopRightRadius: theme.radii.small,
        })}
      >
        {renderName()}
        {isScore && (
          <div
            css={{
              width: 16,
              height: 16,
              transform:
                scoreLevel === 0
                  ? undefined
                  : scoreLevel === 1
                  ? "rotate(-45deg)"
                  : "rotate(-90deg)",
            }}
          >
            <ArrowLeft
              fill={
                scoreLevel === 0
                  ? theme.colors.error
                  : scoreLevel === 1
                  ? theme.colors.lightBlue
                  : theme.colors.brightGreen
              }
            />
          </div>
        )}
      </Text>
      <div
        css={(theme) => ({
          paddingLeft: theme.padding.default,
          paddingRight: theme.padding.default,
          paddingBottom: "12px",
        })}
      >
        {renderContext()}
        {renderHoverData()}
        <div
          onClick={handleClicked}
          style={{
            borderRadius: "30px",
            padding: "1px",
            textAlign: "center",
            width: "100%",
            height: "27px",
          }}
          className="map-disabled-hover-tooltip-btn"
        >
          <Button
            type="primary"
            style={{
              borderRadius: "30px",
              padding: "2px",
              textAlign: "center",
              width: "100%",
              background: theme.colors.red,
              backgroundImage: "linear-gradient(to right, #ff7e5f, #feb47b) 1",
              height: "25px",
            }}
          >
            Click to see data
          </Button>
        </div>
        {isScore && <Flex></Flex>}
      </div>
    </div>
  );
};

export default HoverPinDisabled;
