import { FC } from "react";

interface Props {
  width?: number | string;
  height?: number | string;
  color?: string;
}

const FilterIcon: FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_2135_1341)">
        <path
          d="M23 2H1L9.8 12.5111V19.7778L14.2 22V12.5111L23 2Z"
          stroke={color || "#878788"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2135_1341">
          <rect width="23.8983" height="23.8983" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FilterIcon;
