import { Collapse, Popover } from "antd";
import { Flex } from "components/Flex";
import ExploratoryTitle from "components/exploratory/ExploratoryTitle";
import PenalChevron from "components/exploratory/PenalChevron";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import { useUserData } from "hooks/useUser";
import { popoverContent } from "lib/constants";
import { useContext, useState } from "react";

const { Panel } = Collapse;
export const DataPointDropdown = ({
  currentDataPoint,
  geo,
  setColumnsArray,
  currentIndex,
  dropDownOptions,
}) => {
  const { setShowPlanModal, setPlanPopupTrigger } =
    useContext(PlanModalContext);
  const user = useUserData();
  const [activeTabs, setActiveTabs] = useState<string | string[]>([
    "Popular Data",
  ]);

  const handleColumnOrderChange = (option) => {
    if (option.value === "rent_growth_yoy" && geo !== "metro") return;
    const { value: changedDataPoint, premium } = option;
    if (premium && !user?.isPremiumOrBasic) {
      setPlanPopupTrigger(
        `Change datapoint in Table view: ${changedDataPoint}`,
      );
      setShowPlanModal(true);
    }
    setColumnsArray((prevState) => {
      const updatedColumns = [...prevState];

      let swapIndex = updatedColumns.findIndex((item) => {
        return item === changedDataPoint;
      });
      if (swapIndex > -1) {
        updatedColumns[currentIndex] = prevState[swapIndex];
        updatedColumns[swapIndex] = prevState[currentIndex];
      } else {
        updatedColumns[currentIndex] = changedDataPoint;
      }
      return [...updatedColumns];
    });
  };

  const popoverParagraph = <p style={{ padding: "10px" }}>{popoverContent}</p>;

  return (
    <Collapse
      activeKey={activeTabs}
      onChange={(e) => {
        setActiveTabs(e);
      }}
      css={(theme) => ({
        display: "flex",
        flexDirection: "column",
        maxHeight: 400,
        width: 200,
        overflow: "scroll",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
          display: "none",
        },
        ".ant-collapse-content>.ant-collapse-content-box": {
          padding: "5px 0px !important",
        },
        ".ant-collapse-header": {
          padding: "2px 8px !important",
          background: theme.colors.lightBg,
        },
      })}
      key={currentDataPoint}
    >
      {dropDownOptions?.map((group) => {
        if (!group.options?.length) return null;
        return (
          <Panel
            header={
              <Flex
                align="center"
                justify="flex-start"
                css={(theme) => ({
                  gap: theme.gap[1],
                  color: "black",
                })}
              >
                <span>{group.label}</span>
              </Flex>
            }
            key={group.label}
            showArrow={false}
            extra={
              <PenalChevron
                active={(activeTabs as string[]).some(
                  (tab) => tab === group.label,
                )}
              />
            }
          >
            {group.options?.map((option) => {
              if (
                option.label === "Overvalued %" &&
                (geo === "county" || geo === "zip")
              ) {
                return null;
              }
              return (
                <div
                  key={option.value}
                  css={(theme) => ({
                    cursor: "pointer",
                    padding: "2px 8px",
                    ":hover": {
                      background: theme.colors.lightBg,
                    },
                  })}
                  onClick={() => {
                    handleColumnOrderChange(option);
                  }}
                >
                  {option.value === "rent_growth_yoy" && geo !== "metro" ? (
                    <Popover
                      content={popoverParagraph}
                      title="Alert"
                      placement="bottom"
                    >
                      <div>
                        <ExploratoryTitle
                          title={option.label}
                          isPremium={option.premium}
                          isShowPremiumIcon={true}
                          ytVideoCode={option.ytVideoCode}
                          isDisabled={true}
                          option={option}
                        />
                      </div>
                    </Popover>
                  ) : (
                    <ExploratoryTitle
                      title={option.label}
                      isPremium={option.premium}
                      isShowPremiumIcon={true}
                      ytVideoCode={option.ytVideoCode}
                      option={option}
                    />
                  )}
                </div>
              );
            })}
          </Panel>
        );
      })}
    </Collapse>
  );
};
