/** @jsxImportSource @emotion/react */

import useProgressQuery from "hooks/useProgressQuery";
import { ResponsiveContext } from "lib/context";
import Image from "next/image";
import React, {
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { esc } from "../lib/key_codes";
import { Flex } from "./Flex";

interface Props {
  visible: boolean;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  customCss?: any;
  onClose(): void;
}

const DataDrawer = (props: Props) => {
  const { visible, onClose, children } = props;
  const { isTabletOrMobile } = useContext(ResponsiveContext);
  const { setProgressQuery } = useProgressQuery();
  const node = useRef<HTMLDivElement | null>();
  const escFunction = useCallback((event) => {
    if (event.keyCode === esc) {
      onClose();
      setProgressQuery((prevState) => {
        const progress = { ...prevState };
        delete progress?.lnglat;
        return progress;
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <>
      <div
        ref={(ref) => {
          if (ref) node.current = ref;
        }}
        css={(theme) => ({
          width: !isTabletOrMobile ? "auto" : "100vw",
          height: "98%",
          background: theme.colors.white,
          boxShadow: theme.drawerShadow,
          position: "fixed",
          right: !isTabletOrMobile ? 20 : 0,
          bottom: 0,
          left: !isTabletOrMobile ? 300 : 0,
          zIndex: 1050,
          overflow: "auto",
          transition: "transform 0.6s ease",
          transform: `translateY(${visible ? 0 : "120%"})`,
          padding: "31px 0px 50px 0px",
          maxHeight: "98%",
          minHeight: 600,

          ...props.customCss,
        })}
      >
        <Flex
          css={(theme) => ({
            position: "absolute",
            cursor: "pointer",
            top: 18,
            right: 22,
            background: "#5B5B5B",
            borderRadius: theme.radii.small,
            zIndex: 999,
          })}
        >
          <Image
            alt="close"
            src={"/cross.svg"}
            width={37}
            height={37}
            onClick={() => {
              setProgressQuery((prevState) => {
                const progress = { ...prevState };
                delete progress?.lnglat;
                return progress;
              });
              onClose();
            }}
          />
        </Flex>
        {React.cloneElement(children, { onClose })}
      </div>
    </>
  );
};

export default DataDrawer;
