import Spinner from "./Spinner";

export const MapLoader = () => {
  return (
    <div
      css={{
        position: "absolute",
        zIndex: 100,
        background: "#efefef82",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner />
    </div>
  );
};
