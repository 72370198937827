import { useTheme } from "@emotion/react";
import { AutoComplete, Input, InputRef, Tooltip } from "antd";
import SearchIcon from "components/icons/SearchIcon";
import useGeoSearch from "hooks/useGeoSearch";
import { MapContext, ResponsiveContext } from "lib/context";
import { useContext, useEffect, useRef } from "react";
import { Flex } from "../Flex";
import useMapData from "hooks/useMapData";
import { Geo, MapboxGeoMap } from "types/MapContext";

const GeoSearchBar = () => {
  const theme = useTheme();

  const { isTabletOrMobile } = useContext(ResponsiveContext);
  const {
    geo,
    focusSearch,
    suggestions,
    currentLocationSuggestion,
    searchValue,
    showFocusToolTip,
    setSearchZipsViaState,
    reset,
    handleInputChange,
    setShowFocusTooltip,
  } = useGeoSearch();
  const { graphView } = useContext(MapContext);
  const { currentData } = useMapData();

  const input = useRef<InputRef | null>(null);

  useEffect(() => {
    if (focusSearch) {
      input.current?.focus();
    }
  }, [focusSearch]);

  const suggestionClicked = (suggestion) => {
    setSearchZipsViaState("All States");
    input.current?.blur();
    let onlyFly = false;
    if (currentData.length && suggestion.feature) {
      const [mapBoxGeoType] = suggestion.feature.place_type;
      const searchedGeo = MapboxGeoMap[mapBoxGeoType];
      if (searchedGeo === geo) {
        if (geo === Geo.ZIP) {
          onlyFly = currentData.some(
            (d) => d.geo_code === suggestion.feature.text,
          );
        } else {
          onlyFly = true;
        }
      }
    }
    suggestion.onClick(onlyFly);
  };
  return (
    <Flex
      align="center"
      css={(theme) => ({
        flex: 2,
        borderRight: isTabletOrMobile ? "none" : "1px solid #DEDEDE",
        position: "relative",
      })}
    >
      <Tooltip
        open={showFocusToolTip && !graphView}
        placement="topLeft"
        title={"Type here to search"}
        overlayClassName="tooltip-geo-search-focused"
      >
        <AutoComplete
          value={searchValue}
           options={[currentLocationSuggestion, ...(suggestions || [])]}
          //options={suggestions}
          onSearch={(value) => {
            handleInputChange(value);
            setShowFocusTooltip(false);
          }}
          onSelect={(_, suggestion) => {
            suggestionClicked(suggestion);
          }}
          onInputKeyDown={(e) => {
            if (e.key === "Enter" && !searchValue.length) {
              reset();
            }
          }}
          css={{
            display: "flex",
            alignItems: "center",
            fontSize: 16,
            height: 41,
            width: "100%",

            input: {
              "::placeholder": {
                color: theme.colors.text3,
              },
            },

            ".ant-input": {
              paddingLeft: "14px !important",
            },

            ".ant-input-affix-wrapper": {
              padding: 0,
            },

            ".ant-select-selector": {
              flex: 1,
            },

            ".ant-input-prefix": {
              paddingLeft: isTabletOrMobile
                ? theme.padding.medium
                : theme.padding.large,
            },
          }}
        >
          <Input
            ref={(ref) => {
              if (ref) input.current = ref;
            }}
            onPressEnter={() => {
              if (suggestions && suggestions.length > 0) {
                const firstSuggestion = suggestions[0];
                suggestionClicked(firstSuggestion);
              }
            }}
            placeholder="Search your County, City, or ZIP Code"
            bordered={false}
            prefix={
              <Flex justify="center" align="center" id="geo-search-icon">
                <SearchIcon />
              </Flex>
            }
          />
        </AutoComplete>
      </Tooltip>
    </Flex>
  );
};

export default GeoSearchBar;
