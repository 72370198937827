import { MutableRefObject, useEffect } from "react";

export const useOutsideClick = (
  node: MutableRefObject<HTMLDivElement | null>,
  callback: (...args: any) => void,
  ignoredNodeClasses?: string[],
  enable?: boolean,
) => {
  const handleClick = (e) => {
    if (!enable) return;

    let isIgnored = false;
    if (ignoredNodeClasses) {
      isIgnored = ignoredNodeClasses
        .map((ignoredNodeClass) => {
          const parentElement = document.querySelector(`.${ignoredNodeClass}`);
          return parentElement?.contains(e?.target);
        })
        .some((isIgnored) => isIgnored);
    }

    if (node?.current?.contains(e?.target) || isIgnored) {
      return; // inside click or clicked exploratory dropdown
    }

    callback();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
};
