import { useTheme } from "@emotion/react";
import { UilTable } from "@iconscout/react-unicons";
import { Switch } from "antd";
import { Flex } from "components/Flex";
import FilterIcon from "components/icons/FilterIcon";
import useGeoSearch from "hooks/useGeoSearch";
import { MapContext, ResponsiveContext } from "lib/context";
import { event } from "nextjs-google-analytics";
import { usePostHog } from "posthog-js/react";
import { FC, useContext, useState } from "react";
import FiltersModal from "../MapFilter/FiltersModal";
import RoundButton from "./RoundButton";

interface Props {
  sidebarVisible: boolean;
  lastFilterAsExploratory: boolean;
}

const MapControls: FC<Props> = ({
  sidebarVisible,
  lastFilterAsExploratory,
}) => {
  const { isTabletOrMobile } = useContext(ResponsiveContext);
  const posthog = usePostHog();
  const { showToolTip, setShowToolTip, setTableView } = useContext(MapContext);

  const { geo } = useGeoSearch();
  const theme = useTheme();

  const [showFilters, setShowFilters] = useState(false);

  if (isTabletOrMobile)
    return (
      <>
        <Flex
          direction="column"
          css={(theme) => ({
            position: "absolute",
            top: 150,
            zIndex: 150,
            right: theme.margin.medium,
            gap: theme.space[1],
          })}
        >
          <RoundButton
            title="Table"
            icon={<UilTable css={{ color: theme.colors.white }} />}
            color={theme.colors.white}
            backgroundColor={theme.colors.primary}
            onClick={() => setTableView((prev) => !prev)}
          />

          <RoundButton
            title="Filters"
            icon={
              <FilterIcon width={20} height={20} color={theme.colors.white} />
            }
            color={theme.colors.white}
            backgroundColor={theme.colors.primary}
            onClick={() => setShowFilters(true)}
          />
        </Flex>

        <FiltersModal
          geo={geo}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          lastFilterAsExploratory={lastFilterAsExploratory}
        />
      </>
    );

  return (
    <Flex
      justify="end"
      css={(theme) => ({
        position: "absolute",
        bottom: 19,
        zIndex: 150,
        gap: theme.space[1],
        left: sidebarVisible ? 312 : 40,
        transition: "left 0.3s ease",
        WebkitTransition: "left 0.3s ease",
        MozTransition: "left 0.3s ease",
        msTransition: "left 0.3s ease",
        OTransition: "left 0.3s ease",
      })}
    >
      <Flex
        align="center"
        justify="center"
        css={(theme) => ({
          width: 111,
          height: 38,
          backgroundColor: theme.colors.white,
          borderRadius: 65,
          gap: theme.gap[3],
          fontSize: theme.fontSizes.medium,
          fontWeight: theme.fontWeights.bold,
          cursor: "pointer",
        })}
        onClick={() => setShowToolTip((prev) => !prev)}
      >
        <Switch checked={showToolTip} size="small" />
        <span>Tooltip</span>
      </Flex>

      <Flex
        align="center"
        justify="center"
        css={(theme) => ({
          width: 138,
          height: 38,
          backgroundColor: theme.colors.white,
          borderRadius: 65,
          gap: theme.gap[3],
          fontSize: theme.fontSizes.medium,
          fontWeight: theme.fontWeights.bold,
          cursor: "pointer",
        })}
        onClick={() => {
          event(`map-control-table-view`, {
            category: "Map Control",
            label: "Table View",
          });
          posthog.capture("Opened Table");
          setTableView((prev) => !prev);
        }}
      >
        <UilTable />
        <span>Table View</span>
      </Flex>
    </Flex>
  );
};

export default MapControls;
