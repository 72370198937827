// Dependencies
import { debounce } from "lodash";
import Slider from "rc-slider";
import { useEffect, useMemo, useState } from "react";

// Components
import { Flex } from "../../Flex";

// Utilities
import { getMapDisplayValue } from "lib/helpers";
import { getCodeForLabel } from "lib/helpers/exploratoryHelpers";
import datapointAffixes from "lib/options/datapointAffixes";
import { FilterValues, IFilter } from "types/options";
import FilterInput from "./FilterInput";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

interface Props {
  filter: IFilter;
  state: { minVal: number; maxVal: number };
  onChange(val: [number, number]): void;
  onBeforeChange(): void;
}

function FilterRange({ filter, onChange, state, onBeforeChange }: Props) {
  // Local state
  const [value, setValue] = useState<FilterValues>(
    filter.val && filter.val.length > 0
      ? filter.val
      : [state.minVal, state.maxVal],
  );

  // Handlers
  const dispatchChange = useMemo(() => debounce(onChange, 500), [onChange]);
  const handleChange = (val: FilterValues | number[]) => {
    setValue([val[0], val[1]] as FilterValues);
    dispatchChange(val as [number, number]);
  };

  // Effects
  useEffect(() => {
    /*
     * Sometimes, the values will change top-bottom and the component has to
     * deal with it.
     */
    if (
      filter.val &&
      filter.val.length === 2 &&
      (filter.val[0] !== value[0] || filter.val[1] !== value[1])
    ) {
      setValue(filter.val);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.val]);

  // Variables
  const exploratoryCode: string = getCodeForLabel(filter.label);
  const prefix = datapointAffixes[exploratoryCode]?.prefix || "";
  const suffix = datapointAffixes[exploratoryCode]?.postfix || "";
  const isCurrency = prefix === "$";

  return (
    <Flex justify="center" align="center" direction="column">
      <Range
        onBeforeChange={onBeforeChange}
        onChange={handleChange}
        draggableTrack
        min={state.minVal}
        max={state.maxVal}
        value={value as number[]}
        tipProps={{
          placement: "top",
          visible: false,
        }}
        trackStyle={[{ height: 10 }]}
        railStyle={{ height: 10 }}
        tipFormatter={(value) => (
          <span className="tooltip">
            {getMapDisplayValue({ exploratory: exploratoryCode, value })}
          </span>
        )}
        step={0.1}
        css={(theme) => ({
          width: `calc(100% - ${theme.margin.large}px)`,
        })}
      />
      <Flex
        justify="space-between"
        css={(theme) => ({
          marginTop: theme.margin.medium,
          width: "100%",
        })}
      >
        <FilterInput
          exploratoryCode={exploratoryCode}
          isCurrency={isCurrency}
          isMinInput
          onValueChange={(val) => {
            handleChange([parseFloat(val as string), value[1] as number]);
          }}
          state={value}
          suffix={suffix}
        />
        <FilterInput
          exploratoryCode={exploratoryCode}
          isCurrency={isCurrency}
          onValueChange={(val) => {
            handleChange([value[0] as number, parseFloat(val as string)]);
          }}
          state={value}
          suffix={suffix}
        />
      </Flex>
    </Flex>
  );
}

export default FilterRange;
